<template>
<vx-card title="Customers" :subtitle="`Total: ${customers.length}`">
    <template slot="actions">
        <vs-input v-model="searchString" class="m-0 mr-5" @keyup="handleFilter" placeholder="Search"></vs-input>
    </template>
    <vs-table @change-page="handleChangePage" :sst="true" @sort="handleSort" stripe :data="customers" v-if="customers.length">
        <template #thead>
            <vs-th class="width1"></vs-th>
            <vs-th sort-key="displayName">Name</vs-th>
            <vs-th sort-key="emailAddress">Email</vs-th>
            <vs-th sort-key="mobileNumber">Mobile</vs-th>
            <vs-th sort-key="createdDate">Created Date</vs-th>
        </template>
        <template>
            <vs-tr :key="i" v-for="(tr, i) in customers" :data="tr">
                <vs-td>
                    <vs-button color="dark" @click="editSelected(tr.azureObjectId)">Edit</vs-button>
                </vs-td>
                <vs-td>
                    {{ tr.displayName }}
                </vs-td>
                <vs-td>
                    {{ tr.emailAddress }}
                </vs-td>
                <vs-td>
                    {{ tr.mobileNumber }}
                </vs-td>
                <vs-td>
                  {{ tr.createdDate | formatLongDate }}
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
</vx-card>
</template>

<script>
import {
    userData
} from '../../store/api/user';
import _ from 'lodash';

export default {
    data() {
        return {
            customers: [],
            searchString: "",
            noData: {
                title: "No Records Found",
            },
            intervalId: null,
            activePage: 1
        }
    },
    mounted() {
        this.scroll()
    },
    async created() {
        await this.loadCustomers();
    },
    methods: {
        scroll() {
            window.onscroll = () => {
                if (Math.ceil(document.documentElement.scrollTop) + document.documentElement.clientHeight === document.documentElement.scrollHeight) {
                    console.log("data checking", Math.ceil(document.documentElement.scrollTop) + document.documentElement.clientHeight, "=", document.documentElement.scrollHeight)
                    this.activePage += 1;
                    this.loadCustomers();
                }
            };
        },
        async loadCustomers() {
            let customerlist = await userData.searchCustomer(this.searchString, this.activePage);

            if (this.activePage === 1) {
                this.customers = []
            }

            customerlist.forEach(element => {
                this.customers.push(element);
            });

        },
        async handleFilter() {
            this.activePage = 1;
            if (this.intervalId) {
              clearTimeout(this.intervalId)
            }
            this.intervalId = setTimeout(async() => {
                await this.loadCustomers();
            }, 800);
        },
        handleSort(key, active) {
            this.customers = _.orderBy(this.customers, key, active);
        },
        editSelected(id) {
            this.$router.push(`/admin/Customers/edit/${id}`);
        },
        async handleChangePage(page) {
            this.activePage = page
            await this.loadCustomers()
        }
    }
}
</script>
