<template>
<div>
    <CustomerList></CustomerList>
</div>
</template>

<script>
import CustomerList from './CustomerList'

export default {
  components: {
	  CustomerList
  },
  methods:{
  }
}
</script>
